<template>
	<div>
		<b-modal
			id="modal-add-game"
			cancel-variant="outline-secondary"
			:ok-title="$t('Add')"
			:cancel-title="$t('Close')"
			ok-variant="success"
			centered
			modal-class="modal-success"
			:title="$t('Add game')"
			@ok="confirm"
			@hidden="resetModal()"
		>
			<!-- form -->
			<validation-observer ref="addGameModal">
				<b-form
					method="POST"
					@submit.prevent="validationForm"
				>
					<b-form-group
						label-for="name"
					>
						<label class="mb-1">{{ $t('Name') }}</label>
						<validation-provider
							#default="{ errors }"
							name="name"
                            rules="required"
						>
							<b-form-input
								id="name"
								v-model="game.name"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
                    <b-form-group
						label-for="code"
					>
						<label class="mb-1">{{ $t('Code') }}</label>
						<validation-provider
							#default="{ errors }"
							name="code"
                            rules="required"
						>
							<b-form-input
								id="code"
								v-model="game.code"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

                    <b-form-group
						label-for="gameType"
					>
						<label class="mb-1">{{ $t('Game type') }}</label>
						<validation-provider
                            #default="{ errors }"
                            name="gameType"
                            rules="required"
                        >
                            <v-select
                                v-model="game.type"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="gameTypeOptions"
                                :reduce="val => val.id"
                                :clearable="false"
                                placeholder="Select type"
                                label="name"
                                input-id="type"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
					</b-form-group>

                    <b-form-group
						label-for="product"
					>
						<label class="mb-1">{{ $t('Product') }}</label>
						<validation-provider
                            #default="{ errors }"
                            name="product"
                            rules="required"
                        >
                            <v-select
                                v-model="game.product"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="productOptions"
                                :reduce="val => val.id"
                                :clearable="false"
                                placeholder="Select product"
                                label="name"
                                input-id="product"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
					</b-form-group>

                    <b-form-group
                        label-for="status"
                    >
                        <label class="mb-1 d-block">Is Support For Fun?</label>
                        <b-form-radio
                            v-model="game.support_for_fun"
                            name="status"
                            value="1"
                            inline
                        >
                            Yes
                        </b-form-radio>
                        <b-form-radio
                            v-model="game.support_for_fun"
                            name="status"
                            value="2"
                            inline
                        >
                            No
                        </b-form-radio>
                    </b-form-group>

                    <b-form-group
                        label-for="images"
                    >
                        <label for=""><span class="text-danger">*</span> {{ $t('Images') }} <span class="text-secondary">(Không > 10Mb)</span></label>
                        <validation-provider
                            #default="{ errors }"
                            name="images"
                            rules="required|size:10240"
                        >
                            <b-form-file
                                v-model="game.images"
                                multiple
                                input-id="images"
                                accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        
                    </b-form-group>
                    <b-button
                        v-show="game.images.length != 0"
                        variant="outline-danger"
                        class="btn-sm btn-icon"
                        @click="game.images = []"
                    >
                        <feather-icon icon="XIcon"/>
                    </b-button>

                    <b-form-group
                        label-for="image"
                    >
                        <label for=""><span class="text-danger">*</span> {{ $t('Image detail') }} <span class="text-secondary">(Không > 10Mb)</span></label>
                        <validation-provider
                            #default="{ errors }"
                            name="imageDetail"
                            rules="required|size:10240"
                        >
                            <b-form-file
                                v-model="game.image_detail"
                                input-id="imageDetail"
                                accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-button
                        v-show="game.image_detail"
                        variant="outline-danger"
                        class="btn-sm btn-icon"
                        @click="game.image_detail = null"
                    >
                        <feather-icon icon="XIcon"/>
                    </b-button>

                    <b-form-group
						label-for="language"
					>
						<label class="mb-1">{{ $t('Language') }}</label>
						<validation-provider
                            #default="{ errors }"
                            name="language"
                            rules="required"
                        >
                            <v-select
                                v-model="game.language"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="languageOptions"
                                :reduce="val => val.id"
                                :clearable="false"
                                placeholder="Select language"
                                label="name"
                                input-id="language"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
					</b-form-group>

                    <b-form-group
						label-for="description"
					>
						<label class="mb-1">{{ $t('Description detail') }}</label>
						<validation-provider
							#default="{ errors }"
							name="description"
                            rules="required"
						>
							<b-form-textarea
								id="code"
								v-model="game.description"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BTabs, BTab, BForm, BFormGroup, BFormInput, BFormFile, BFormRadio, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default {
	components: {
		BLink, BRow, BCol, BTabs, BTab, BForm, BFormGroup, BFormInput, BFormFile, BFormRadio, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			game: {
                name: '',
                code: '',
                type: '',
                product: '',
                support_for_fun: '',
                images: [],
                image_detail: null,
                language: null,
                description: '',
			},
		}
	},
    props: {
        gameTypeOptions: {
            type: Array,
            default: null,
        },
        productOptions: {
            type: Array,
            default: null,
        },
        languageOptions: {
            type: Array,
            default: null,
        }
    },
	methods: {
		validationForm() {
			this.$refs.addGameModal.validate().then(success => {
				if (success) {
					// this.addGameType(this.gameType)
				}
			})
		},
		/* async addGameType(postData) {
			await this.$store.dispatch('frontend/addPost', postData)
				.then(response => {
					console.log(response)
					if (response.status === 200) {
						if (response.data.code == '00') {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'success',
									icon: 'CheckIcon',
									variant: 'success',
								},
							})
                            this.$emit('refetch-data')
							this.$nextTick(() => {
								setTimeout(() => {
									this.$bvModal.hide('modal-add-post')
									this.resetModal()
								}, '500')
							})
							this.$emit('refetch-data')
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						}
					}
				})
		}, */
		resetModal() {
            this.game = {
                name: '',
                code: '',
                type: '',
			}
		},
		confirm(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationForm()
		}
	},
	setup() {

		return {
		}
	},
}
</script>