var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-add-game","cancel-variant":"outline-secondary","ok-title":_vm.$t('Add'),"cancel-title":_vm.$t('Close'),"ok-variant":"success","centered":"","modal-class":"modal-success","title":_vm.$t('Add game')},on:{"ok":_vm.confirm,"hidden":function($event){return _vm.resetModal()}}},[_c('validation-observer',{ref:"addGameModal"},[_c('b-form',{attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Name')))]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.game.name),callback:function ($$v) {_vm.$set(_vm.game, "name", $$v)},expression:"game.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Code')))]),_c('validation-provider',{attrs:{"name":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.game.code),callback:function ($$v) {_vm.$set(_vm.game, "code", $$v)},expression:"game.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"gameType"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Game type')))]),_c('validation-provider',{attrs:{"name":"gameType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.gameTypeOptions,"reduce":function (val) { return val.id; },"clearable":false,"placeholder":"Select type","label":"name","input-id":"type"},model:{value:(_vm.game.type),callback:function ($$v) {_vm.$set(_vm.game, "type", $$v)},expression:"game.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"product"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Product')))]),_c('validation-provider',{attrs:{"name":"product","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.productOptions,"reduce":function (val) { return val.id; },"clearable":false,"placeholder":"Select product","label":"name","input-id":"product"},model:{value:(_vm.game.product),callback:function ($$v) {_vm.$set(_vm.game, "product", $$v)},expression:"game.product"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"status"}},[_c('label',{staticClass:"mb-1 d-block"},[_vm._v("Is Support For Fun?")]),_c('b-form-radio',{attrs:{"name":"status","value":"1","inline":""},model:{value:(_vm.game.support_for_fun),callback:function ($$v) {_vm.$set(_vm.game, "support_for_fun", $$v)},expression:"game.support_for_fun"}},[_vm._v(" Yes ")]),_c('b-form-radio',{attrs:{"name":"status","value":"2","inline":""},model:{value:(_vm.game.support_for_fun),callback:function ($$v) {_vm.$set(_vm.game, "support_for_fun", $$v)},expression:"game.support_for_fun"}},[_vm._v(" No ")])],1),_c('b-form-group',{attrs:{"label-for":"images"}},[_c('label',{attrs:{"for":""}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Images'))+" "),_c('span',{staticClass:"text-secondary"},[_vm._v("(Không > 10Mb)")])]),_c('validation-provider',{attrs:{"name":"images","rules":"required|size:10240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"multiple":"","input-id":"images","accept":"image/jpeg,image/png,image/gif,image/svg,image/jpg"},model:{value:(_vm.game.images),callback:function ($$v) {_vm.$set(_vm.game, "images", $$v)},expression:"game.images"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.game.images.length != 0),expression:"game.images.length != 0"}],staticClass:"btn-sm btn-icon",attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.game.images = []}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1),_c('b-form-group',{attrs:{"label-for":"image"}},[_c('label',{attrs:{"for":""}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Image detail'))+" "),_c('span',{staticClass:"text-secondary"},[_vm._v("(Không > 10Mb)")])]),_c('validation-provider',{attrs:{"name":"imageDetail","rules":"required|size:10240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"input-id":"imageDetail","accept":"image/jpeg,image/png,image/gif,image/svg,image/jpg"},model:{value:(_vm.game.image_detail),callback:function ($$v) {_vm.$set(_vm.game, "image_detail", $$v)},expression:"game.image_detail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.game.image_detail),expression:"game.image_detail"}],staticClass:"btn-sm btn-icon",attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.game.image_detail = null}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1),_c('b-form-group',{attrs:{"label-for":"language"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Language')))]),_c('validation-provider',{attrs:{"name":"language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.languageOptions,"reduce":function (val) { return val.id; },"clearable":false,"placeholder":"Select language","label":"name","input-id":"language"},model:{value:(_vm.game.language),callback:function ($$v) {_vm.$set(_vm.game, "language", $$v)},expression:"game.language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"description"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Description detail')))]),_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"code","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.game.description),callback:function ($$v) {_vm.$set(_vm.game, "description", $$v)},expression:"game.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }